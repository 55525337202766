import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Overriding client timeouts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const Warning = makeShortcode("Warning");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overriding-client-timeouts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overriding-client-timeouts",
        "aria-label": "overriding client timeouts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overriding client timeouts`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-typeclientbuilder"
        }}>{`Using type://ClientBuilder`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-typewebclientrequestpreparation"
        }}>{`Using type://WebClientRequestPreparation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-typerequestoptions"
        }}>{`Using type://RequestOptions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adjusting-connection-level-timeouts"
        }}>{`Adjusting connection-level timeouts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-jvm-system-properties"
        }}>{`Using JVM system properties`}</a></li>
    </ul>
    <p>{`Sometimes, the default timeouts used by the Armeria clients do not suit a particular scenario well.
In these cases, you might want to override the timeout settings.`}</p>
    <h2 {...{
      "id": "using-typeclientbuilder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typeclientbuilder",
        "aria-label": "using typeclientbuilder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://ClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html"
      }}>{`type://ClientBuilder`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.time.Duration;

import com.linecorp.armeria.client.Clients;

int responseTimeout = 30;
int writeTimeout = 10;

HelloService.Iface client =
    Clients.builder("tbinary+http://example.com/hello")
           .responseTimeout(Duration.ofSeconds(responseTimeout))
           .writeTimeout(Duration.ofSeconds(writeTimeout))
           .build(HelloService.Iface.class);
`}</code></pre>
    <h2 {...{
      "id": "using-typewebclientrequestpreparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typewebclientrequestpreparation",
        "aria-label": "using typewebclientrequestpreparation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://WebClientRequestPreparation:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientRequestPreparation.html"
      }}>{`type://WebClientRequestPreparation`}</a></h2>
    <p>{`You can override the properties defined in `}<a parentName="p" {...{
        "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
      }}>{`type://RequestOptions`}</a>{` at request level
using `}<a parentName="p" {...{
        "href": "type://WebClient#prepare():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html#prepare()"
      }}>{`type://WebClient#prepare()`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.WebClient;

WebClient client = WebClient.of("http://example.com"); 
client.prepare()
      .get("/hello")
      .responseTimeout(Duration.ofSeconds(30))
      .writeTimeout(Duration.ofSeconds(10)
      .maxResponseLength(1024)
      .execute();
`}</code></pre>
    <h2 {...{
      "id": "using-typerequestoptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typerequestoptions",
        "aria-label": "using typerequestoptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
      }}>{`type://RequestOptions`}</a></h2>
    <p>{`You can also create a `}<a parentName="p" {...{
        "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
      }}>{`type://RequestOptions`}</a>{` to override the properties at request level. The
created `}<a parentName="p" {...{
        "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
      }}>{`type://RequestOptions`}</a>{` could be passed to `}<a parentName="p" {...{
        "href": "type://WebClient#execute(HttpRequest,RequestOptions):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html#execute(com.linecorp.armeria.common.HttpRequest,com.linecorp.armeria.client.RequestOptions)?full"
      }}>{`type://WebClient#execute(HttpRequest,RequestOptions)?full`}</a>{`
with an `}<a parentName="p" {...{
        "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
      }}>{`type://HttpRequest`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.RequestOptions;
import com.linecorp.armeria.common.HttpRequest;

HttpRequest req = HttpRequest.of(HttpMethod.GET, "/hello");
RequestOptions options = 
    RequestOptions.builder()
                  .responseTimeout(Duration.ofSeconds(30))
                  .writeTimeout(Duration.ofSeconds(10)
                  .maxResponseLength(1024)
                  .build();
client.execute(req, options);
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/advanced-structured-logging#response-properties"
        }}>{`Response properties`}</a>{` to understand when the
response timeout is scheduled.`}</p>
    </Tip>
    <h2 {...{
      "id": "adjusting-connection-level-timeouts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adjusting-connection-level-timeouts",
        "aria-label": "adjusting connection level timeouts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjusting connection-level timeouts`}</h2>
    <p>{`You need to build a non-default `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` using `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html"
      }}>{`type://ClientFactoryBuilder`}</a>{` to override the default
connection-level timeouts such as connect timeout and idle timeout programmatically:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientFactory;

ClientFactory factory =
    ClientFactory.builder()
                 // Increase the connect timeout from 3.2s to 10s.
                 .connectTimeout(Duration.ofSeconds(10))
                 // Shorten the idle connection timeout from 10s to 5s.
                 .idleTimeout(Duration.ofSeconds(5))
                 // Note that you can also adjust other connection-level
                 // settings such as enabling HTTP/1 request pipelining.
                 .useHttp1Pipelining(true)
                 .build();
`}</code></pre>
    <p>{`Note that `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` implements `}<inlineCode parentName="p">{`java.lang.AutoCloseable`}</inlineCode>{` and thus needs to be closed when you
terminate your application. On `}<inlineCode parentName="p">{`close()`}</inlineCode>{`, `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` will close all the connections it manages
and abort any requests in progress.`}</p>
    <h2 {...{
      "id": "using-jvm-system-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-jvm-system-properties",
        "aria-label": "using jvm system properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using JVM system properties`}</h2>
    <p>{`You can override the default client timeouts by specifying the following JVM system properties if you do not
prefer setting it programmatically:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.defaultClientIdleTimeoutMillis=<integer>`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`the default client-side idle timeout of a connection for keep-alive in milliseconds. Default: `}<inlineCode parentName="li">{`10000`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.defaultConnectTimeoutMillis=<integer>`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`the default client-side timeout of a socket connection attempt in milliseconds. Default: `}<inlineCode parentName="li">{`3200`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.defaultWriteTimeoutMillis=<integer>`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`the default client-side timeout of a socket write attempt in milliseconds. Default: `}<inlineCode parentName="li">{`1000`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.defaultResponseTimeoutMillis=<integer>`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`the default client-side timeout of a response in milliseconds. Default: `}<inlineCode parentName="li">{`15000`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <Warning mdxType="Warning">
      <p>{`The JVM system properties have effect only when you did not specify them programmatically.
See `}<a parentName="p" {...{
          "href": "type://Flags:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html"
        }}>{`type://Flags`}</a>{` for the complete list of JVM system properties in Armeria.`}</p>
    </Warning>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      